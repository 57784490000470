import { useEffect, useState } from 'react';
import { butterAPI } from '@/lib/apis/butter-api';
import { BUTTER_PAGES } from '@/lib/apis/butter-api/butter-api.constants';
import {
  ButterCountriesListPageData,
  ButterCountryListItem,
} from '@/lib/apis/butter-api/types';
import { defaultLocale } from '@/lib/i18n/i18n.config';

export const useGetCountriesList = () => {
  const [countryData, setCountryData] = useState<ButterCountryListItem[]>([]);
  const [countryError, setCountryError] = useState<unknown>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await butterAPI.getPage<ButterCountriesListPageData>({
          locale: defaultLocale,
          pageSlug: BUTTER_PAGES.COUNTRIES_LIST,
          preview: false,
        });

        const countries = data?.fields?.countries;

        if (countries) {
          setCountryData(countries);
        }
      } catch (error: unknown) {
        console.log(error);
        setCountryError('error fetching data!');
      }
    };

    fetchData();
  }, []);

  return { countryData, countryError };
};
