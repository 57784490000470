import { useSelector } from 'react-redux';
import { PermissionID } from '@mygrimme/types';
import { getUser, selectIsUserLoaded } from '~/redux/auth.slice';
import { UserGridPermissions } from '~/utils/consts';

type PermissionKey = UserGridPermissions | PermissionID;
type PermissionGroup = {
  operator?: 'AND' | 'OR';
  permissions: (PermissionKey | PermissionGroup)[];
};
/**
 * Hook to check if the user has the specified permissions.
 *
 * @param {PermissionGroup} permissionGroup - The group of permissions to check.
 * @returns {boolean | undefined} - Returns `true` if the user has the required permissions,
 *                                  `false` if not, and `undefined` if the user data is not loaded yet.
 *
 * Below example means -> `PERMISSION_1 && (PERMISSION_2 || PERMISSION_3)`
 * @example
 * const hasPermission = useHasPermission({
 *   operator: 'AND',
 *   permissions: [
 *     'PERMISSION_1',
 *     {
 *       operator: 'OR',
 *       permissions: ['PERMISSION_2', 'PERMISSION_3'],
 *     },
 *   ],
 * });
 */
export const useHasPermission = (
  permissionGroup: PermissionGroup,
): boolean | undefined => {
  const userData = useSelector(getUser);
  const userPermissions = userData?.Permissions ?? [];
  const userIsLoaded = useSelector(selectIsUserLoaded);

  const evaluateGroup = ({
    operator = 'AND',
    permissions,
  }: PermissionGroup): boolean => {
    if (operator === 'AND') {
      return permissions.every(evaluate);
    } else if (operator === 'OR') {
      return permissions.some(evaluate);
    }

    return false;
  };

  const evaluate = (permissions: PermissionKey | PermissionGroup): boolean => {
    if (typeof permissions === 'string') {
      return !!userPermissions.find(
        (userPermission) => userPermission.key === permissions,
      );
    } else {
      return evaluateGroup(permissions);
    }
  };

  if (!userIsLoaded) return undefined;

  return evaluateGroup(permissionGroup);
};

/**
 * Preset permission groups for common permission checks.
 */
export const PermissionGroupPresets: Record<string, PermissionGroup> = {
  HAS_OLD_ADMIN_RIGHTS: {
    permissions: [PermissionID.ADMIN],
  },
  HAS_TELEMETRY_ACCESS: {
    operator: 'OR',
    // Check old and new telemetry permissions.
    permissions: [UserGridPermissions.CONNECTIVITY, PermissionID.TELEMETRY],
  },
};
