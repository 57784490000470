import { useEffect, useState } from 'react';
import { useLocale } from 'next-intl';
import { useDebounce } from '../useDebounce';

export const useLocationAutoComplete = () => {
  const [location, setLocation] = useState<string>('');
  const locale = useLocale();
  const [locationPredictions, setLocationPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const debouncedLocationValue = useDebounce(location, 1000);

  useEffect(() => {
    if (!debouncedLocationValue) {
      setLocationPredictions([]);

      return;
    }

    const autocompleteOptions: google.maps.places.AutocompletionRequest = {
      input: debouncedLocationValue,
      language: locale,
      types: ['postal_code'],
    };

    if (!window.google) return;

    const service = new window.google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      autocompleteOptions,
      (locationPredictions, status) => {
        if (status === 'OK') {
          setLocationPredictions(locationPredictions || []);
        } else {
          setLocationPredictions([]);
        }
      },
    );
  }, [debouncedLocationValue, locale]);

  return {
    location,
    locationPredictions,
    setLocation,
  };
};
