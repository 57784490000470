import { environment } from '~/environments';
import { defaultLocale } from '@/lib/i18n/i18n.config';
import { ContactAssistantDetailsFetchResponse, ContactDetails } from './types';

export class TranslationsAPI {
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = environment.translationApi;
  }

  async fetchTranslationKeys(locale: string) {
    const url = new URL(`${this.baseUrl}/translations`);
    url.searchParams.append('language', locale);

    const response = await fetch(url);
    const responseToJson = await response.json();

    if (!response.ok) {
      console.error(
        `[TranslationsAPI] Error while fetching translation keys. Response: ${JSON.stringify(responseToJson)}`,
      );
      return {};
    }

    return responseToJson;
  }

  async fetchContactAssistantDetails(params?: {
    countryCode?: string;
    zipcode?: string;
  }): Promise<ContactDetails | null> {
    const url = new URL(`${this.baseUrl}/contact-assistant-details`);

    url.search = new URLSearchParams({
      companyId: '100',
      countryCode: params?.countryCode || defaultLocale,
      languageId: 'de',
      zipCode: params?.zipcode || '00000',
    }).toString();

    const response = await fetch(url);
    const responseToJson: ContactAssistantDetailsFetchResponse =
      await response.json();

    if (!response.ok) {
      const stringifiedResponse = JSON.stringify(responseToJson);

      console.error(
        `[TranslationsAPI] Error while fetching translation keys. Response: ${stringifiedResponse}`,
      );

      throw Error(JSON.stringify(stringifiedResponse));
    }

    return responseToJson?.query?.salesResponsible || null;
  }
}

export const translationsAPI = new TranslationsAPI();
