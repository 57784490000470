import { useEffect, useState } from 'react';
import { translationsAPI } from '@/lib/apis/translations-api';
import { useGeolocation } from '../useGeolocation';

export type TContactDetails = {
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  phone: string;
  photo: string;
};

export const useContactDetails = () => {
  const [data, setData] = useState<TContactDetails | null>(null);
  const [dataError, setError] = useState<Error | unknown>(null);

  const geolocationData = useGeolocation();

  useEffect(() => {
    const contact = async () => {
      try {
        const contactDetails =
          await translationsAPI.fetchContactAssistantDetails({
            countryCode: geolocationData.countryCode,
            zipcode: geolocationData.zipcode,
          });

        setData(contactDetails);
      } catch (error) {
        setError(error);
      }
    };

    contact();
  }, [geolocationData]);

  return { data, error: dataError };
};
