import { environment } from '~/environments';
import { ButterPage, GlobalSettingsPageData } from './types';
import { BUTTER_PAGES } from './butter-api.constants';
import {
  ButterCollection,
  ButterCollectionFetchResult,
} from './types/general/collection';

export class ButterAPI {
  private readonly baseApiUrl = 'https://api.buttercms.com/v2/';
  private readonly baseCdnUrl = 'https://cdn.buttercms.com';
  private readonly cacheTTL = 60;
  private readonly authToken: string;

  constructor() {
    this.authToken = environment.cmsAuthToken;
  }

  private buildPageGetUrl({
    locale,
    pageSlug,
    pageType,
    preview,
  }: {
    locale: string;
    pageSlug: string;
    pageType: string;
    preview?: boolean;
  }) {
    const url = new URL(`pages/${pageType}/${pageSlug}`, this.baseApiUrl);

    url.searchParams.append('auth_token', this.authToken);
    url.searchParams.append('locale', locale);
    url.searchParams.append('preview', preview ? '1' : '0');

    return url.href;
  }

  private buildPagesGetUrl({
    locale,
    pageType,
    preview,
  }: {
    locale: string;
    pageType: string;
    preview?: boolean;
  }) {
    const url = new URL(`pages/${pageType}`, this.baseApiUrl);

    url.searchParams.append('auth_token', this.authToken);
    url.searchParams.append('locale', locale);
    url.searchParams.append('preview', preview ? '1' : '0');

    return url.href;
  }

  async getPage<T>({
    locale,
    pageSlug,
    pageType = '*',
    preview,
  }: {
    locale: string;
    pageSlug: BUTTER_PAGES;
    pageType?: string;
    preview?: boolean;
  }): Promise<ButterPage<T> | null> {
    const previewResponse = await fetch(
      this.buildPageGetUrl({ locale, pageSlug, pageType, preview: true }),
      { next: { revalidate: preview ? 0 : this.cacheTTL } },
    );

    const parsedPreviewResponse = (await previewResponse.json()) as {
      data: ButterPage<T>;
    };

    if (!previewResponse.ok) {
      return null;
    }

    const { data: pagePreview } = parsedPreviewResponse;

    if (preview || pagePreview.status === 'published') {
      return pagePreview;
    }

    if (!pagePreview.published) {
      return null;
    }

    const publishedPageResponse = await fetch(
      this.buildPageGetUrl({ locale, pageSlug, pageType, preview: false }),
      { next: { revalidate: this.cacheTTL } },
    );

    const parsedPublishedPageResponse = await publishedPageResponse.json();

    return parsedPublishedPageResponse.data;
  }

  getCollection = async <T extends Record<PropertyKey, unknown>>(params: {
    filter?: { type_key: string };
    keys: string[];
    locale: string;
    page?: number;
    page_size?: number;
    preview?: boolean;
  }): Promise<ButterCollection<T>> => {
    const { filter, keys, locale, page, page_size, preview } = params;
    const url = new URL(`content`, this.baseApiUrl);

    url.search = new URLSearchParams([
      ...keys.map((key) => ['keys', key]), // [["keys","key1"],["keys","key2"], ...]
      ...(Object.entries({
        auth_token: this.authToken,
        'fields.type_key': filter?.type_key,
        levels: '2',
        locale,
        page: (page ?? 1).toString(),
        page_size: (page_size ?? 10).toString(),
        preview: (preview ? 1 : 0).toString(),
      }).filter(([_, value]) => !!value) as [string, string][]), // [["levels","2"],["locale","de"], ...]
    ]).toString();

    try {
      const response = await fetch(url.href, {
        next: { revalidate: this.cacheTTL },
      });

      const parsedResponse: ButterCollectionFetchResult<T> =
        await response.json();

      if (!response.ok) {
        throw new Error(JSON.stringify(parsedResponse));
      }

      return { data: parsedResponse.data, error: false };
    } catch (error) {
      console.error('Error loading collection data:', error);
      return { data: null, error: true };
    }
  };

  getContent = async <T = Record<string, unknown>>(params: {
    keys: string[];
    levels?: number;
    locale: string;
    preview?: boolean;
  }): Promise<T> => {
    const { keys, levels = 3, locale, preview } = params;
    const url = new URL(`content`, this.baseApiUrl);

    url.search = new URLSearchParams([
      ...keys.map((key) => ['keys', key]), // [["keys","key1"],["keys","key2"], ...]
      ...(Object.entries({
        auth_token: this.authToken,
        levels: levels,
        locale,
        preview: (preview ? 1 : 0).toString(),
      }).filter(([_, value]) => !!value) as [string, string][]), // [["levels","2"],["locale","de"], ...]
    ]).toString();

    const response = await fetch(url.href, {
      next: { revalidate: this.cacheTTL },
    });

    const parsedResponse: ButterCollectionFetchResult<T> =
      await response.json();

    if (!response.ok) {
      throw new Error(JSON.stringify(parsedResponse));
    }

    return parsedResponse.data;
  };

  async listPages<P>({
    locale,
    pageType,
    preview,
  }: {
    locale: string;
    pageType: string;
    preview: boolean;
  }) {
    const response = await fetch(
      this.buildPagesGetUrl({ locale, pageType, preview }),
      { next: { revalidate: preview ? 0 : this.cacheTTL } },
    );

    const parsedResponse = (await response.json()) as { data: ButterPage<P>[] };

    if (!response.ok) {
      console.error(
        `[ButterCMSApi] Error while fetching pages list. Input: ${JSON.stringify({ locale, pageType, preview })}. Output ${JSON.stringify(parsedResponse)}`,
      );

      throw new Error('[ButterCMSApi] Error while fetching pages list');
    }

    return parsedResponse.data;
  }

  seoImageResize(butterUrl: string): string {
    const butterSrc = butterUrl.split('/').pop();

    return `${this.baseCdnUrl}/resize=width:1200,height:630,fit:clip/output=format:jpg,quality:75/${butterSrc}`;
  }

  prepareFullHDImage = (butterUrl?: string) => {
    const butterSrc = butterUrl?.split('/').pop();

    return `${this.baseCdnUrl}/resize=w:1920,h:1080,f:max,a:center/output=format:jpg,quality:75/${butterSrc}`;
  };

  async fetchGlobalSettings(params: { locale: string; preview?: boolean }) {
    const { locale, preview = false } = params;

    const response = await this.getPage<GlobalSettingsPageData>({
      locale,
      pageSlug: BUTTER_PAGES.GLOBAL_SETTINGS,
      preview,
    });

    return response;
  }
}

export const butterAPI = new ButterAPI();
