export enum BUTTER_PAGES {
  SIDEBAR_NAVIGATION = 'mygrimmecom-sidebar-navigation-settings',
  QUICKLINKS_SETTINGS = 'mygrimmecom-quicklinks-settings',
  MY_GRIMME_LANDING_PAGE = 'my-grimme-landingpage',
  GLOBAL_SETTINGS = 'global-settings-v3',
  COUNTRIES_LIST = 'countries-list',
}

export enum ButterContent {
  CROP_COLORS = 'cropvarietycolors',
  CROP_TYPES = 'croptypes',
  MYGRIMME_INTEGRATION = 'mygrimme_integration',
}
